<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon>
        <v-icon v-bind="attrs" v-on="on"> mdi-trash-can-outline </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> </v-card-title>
      <v-card-text>予約を取り消します。よろしいですか？</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> はい </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    send() {
      this.dialog = false;
      this.$emit("my-click");
    },
  },
};
</script>