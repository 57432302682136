<template>
  <v-row class="fill-height">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            本日
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <RoomList @deleted-room="getReserveList()"></RoomList>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          locale="ja-jp"
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          type="week"
          @click:event="showEvent"
          :weekdays="weekdays"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
        >
          <v-card min-width="250px" flat>
            <v-toolbar :color="selectedEvent.color" dark elevation="0">
              <v-toolbar-title>{{ selectedEvent.roomname }}</v-toolbar-title>

              <v-spacer></v-spacer>
              <DeleteRoomReserve
                v-if="
                  user.id == selectedEvent.userid ||
                  user.id == selectedEvent.registuser
                "
                @my-click="deleteReserve(selectedEvent.id)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </DeleteRoomReserve>
            </v-toolbar>
            <v-card-text>
              <p>
                <v-chip label class="mr-3 chip">日付</v-chip
                >{{ selectedEvent.date }}
              </p>
              <p>
                <v-chip label class="mr-3 chip">時間</v-chip
                >{{ selectedEvent.startHHmm }}～{{ selectedEvent.endHHmm }}
              </p>
              <p>
                <v-chip label class="mr-3 chip">使用者</v-chip
                >{{ selectedEvent.username }}
              </p>
              <p>
                <v-chip label class="mr-3 chip">目的</v-chip
                >{{ selectedEvent.name }}
              </p>
              <p>
                <v-chip label class="mr-3 chip">登録日</v-chip
                >{{ selectedEvent.registdate }}
              </p>
              <p>
                <v-chip label class="mr-3 chip">登録者</v-chip
                >{{ selectedEvent.registusername }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer
              ><v-btn text color="secondary" @click="selectedOpen = false">
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            本日
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <RoomList></RoomList>
        </v-toolbar>
      </v-sheet> </v-col
    ><AddEvent :user="user" @regist="addEvent()" :events="events"></AddEvent>
  </v-row>
</template>
<script>
import axios from "axios";
import moment from "moment";
import RoomList from "@/components/room/RoomList";
import AddEvent from "@/components/room/AddEvent";
import DeleteRoomReserve from "@/components/room/DeleteRoomReserve";
moment.locale("ja");
export default {
  components: { RoomList, AddEvent, DeleteRoomReserve },
  data: () => ({
    snackbar: false,
    msg: "",
    user: "",
    weekdays:
      new Date().getDay() == 0 || new Date().getDay() == 6
        ? [0, 1, 2, 3, 4, 5, 6]
        : [1, 2, 3, 4, 5],
    focus: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
  }),
  mounted() {
    this.getUser();
    this.getReserveList();
    this.$refs.calendar.scrollToTime("08:00");
  },
  methods: {
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    getUser() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-id", {})
        .then((response) => {
          this.user = response.data;
        });
    },
    getReserveList() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-roomreserve", {})
        .then((response) => {
          this.events.splice(0);
          response.data.resereList.forEach((elem) => {
            var data = {
              id: elem._id,
              roomid: elem.roomid,
              roomname: elem.roomname,
              date: moment(elem.timefrom).format("YYYY年MM月DD日"),
              userid: elem.userid,
              username: elem.username,
              name: elem.purpose,
              registuser: elem.registuser,
              registusername: elem.registusername,
              registdate: moment(elem.registdate).format(
                "YYYY年MM月DD日 HH:mm:ss"
              ),
              start: moment(elem.timefrom).format("YYYY-MM-DD HH:mm"),
              startHHmm: moment(elem.timefrom).format("HH:mm"),
              end: moment(elem.timeto).format("YYYY-MM-DD HH:mm"),
              endHHmm: moment(elem.timeto).format("HH:mm"),
              color: elem.color,
              timed: false,
            };
            this.events.push(data);
          });
        });
    },
    addEvent() {
      this.getReserveList();
      this.msg = "予約しました";
      this.snackbar = true;
    },
    deleteReserve(id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-roomreserve", { _id: id })
        .then(() => {
          this.getReserveList();
          this.selectedOpen = false;
          this.msg = "取消しました";
          this.snackbar = true;
        });
    },
  },
};
</script>
<style>
.chip {
  width: 70px;
  justify-content: center;
  align-items: center;
}
</style>