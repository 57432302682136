<template>
  <v-dialog persistent transition="dialog-top-transition" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on">部屋一覧</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>部屋一覧</v-card-title>
        <v-card-text>
          <transition-group name="list" tag="div">
            <v-list-item v-for="item in roomList" :key="item.roomid">
              <v-icon class="mr-3" large :color="item.color">mdi-square</v-icon
              >{{ item.name }}
              <DeleteRoom
                v-if="authority.room"
                @my-click="deleteRoom(item.roomid)"
              ></DeleteRoom>
            </v-list-item>
          </transition-group>
          <v-list-item v-if="!addRoomFlg && authority.room">
            <v-icon @click="addRoomFlg = true">mdi-plus</v-icon>
          </v-list-item>
          <v-list-item v-if="addRoomFlg" ref="newRoom">
            <v-icon
              class="mr-3"
              large
              :color="newRoom.color"
              @click="getRandomColor()"
              >mdi-square</v-icon
            >
            <v-form v-model="valid" ref="form"
              ><v-text-field
                :rules="nameRules"
                v-model="newRoom.name"
                label="部屋名"
              ></v-text-field
            ></v-form>
            <v-btn fab small color="primary" outlined @click="addRoom()"
              >追加</v-btn
            >
          </v-list-item>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
import getRandomColor from "../../func/getRandomColor";
import DeleteRoom from "@/components/room/DeleteRoom";

export default {
  components: { DeleteRoom },
  data() {
    return {
      authority: [],
      valid: false,
      nameRules: [(v) => !!v || "入力してください"],
      dialog: false,
      addRoomFlg: false,
      roomList: [{}],
      newRoom: {
        roomid: "",
        color: getRandomColor(),
        name: "",
      },
    };
  },
  methods: {
    getRoomList() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-room", {
          params: {},
        })
        .then((response) => {
          this.roomList.splice(0);
          response.data.roomList.forEach((elem) => {
            this.roomList.push(elem);
          });
        });
    },
    addRoom() {
      this.newRoom.roomid = Math.random().toString(36);
      if (this.$refs.form.validate()) {
        axios
          .post(process.env.VUE_APP_API_URL + "/regist-room", {
            newRoom: this.newRoom,
          })
          .then(() => {
            this.addRoomFlg = false;
            this.$refs.form.reset();
            this.getRoomList();
          });
      }
    },
    deleteRoom(roomid) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-room", { roomid: roomid })
        .then(() => {
          this.getRoomList();
          this.$emit("deleted-room");
        });
    },
    getUserAuthority() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-authority")
        .then((response) => {
          this.authority = response.data.authority;
        });
    },
    getRandomColor() {
      this.newRoom.color = getRandomColor();
    },
  },
  mounted() {
    this.getRoomList();
    this.getUserAuthority();
  },
};
</script>
<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.list-move {
  transition: transform 0.5s;
}
</style>