<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-snackbar top v-model="snackbar" timeout="3000"> {{ msg }} </v-snackbar>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        fab
        dark
        v-bind="attrs"
        v-on="on"
        class="fixed_btn"
        @click="click()"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card class="pa-3">
      <v-form v-model="valid" ref="form" @submit.prevent>
        <v-card-title class="text-h5"> 予約 </v-card-title>
        <v-card-text>
          <v-select
            :rules="selectfieldRule"
            :items="roomList"
            label="部屋"
            item-text="name"
            item-value="roomid"
            v-model="selectedRoom"
            return-object
          >
            <template v-slot:prepend>
              <v-icon :color="selectedRoom.color">mdi-square</v-icon>
            </template>
          </v-select>
          <DatePicker ref="datepicker" :date.sync="date"></DatePicker>
          <v-row
            ><v-col cols="6"
              ><v-text-field
                :rules="selectfieldRule"
                label="開始時間"
                value="00:00"
                type="time"
                v-model="registData.timefrom"
              ></v-text-field></v-col
            ><v-col cols="6"
              ><v-text-field
                :rules="selectfieldRule"
                label="終了時間"
                value="00:00"
                type="time"
                v-model="registData.timeto"
                @blur="formatTimeto($event)"
              ></v-text-field></v-col
          ></v-row>

          <v-select
            :rules="selectfieldRule"
            :items="userList"
            label="使用者"
            item-text="name"
            item-value="id"
            v-model="selectedUser"
            return-object
          >
          </v-select>
          <v-text-field
            :rules="selectfieldRule"
            label="目的"
            v-model="registData.purpose"
          >
          </v-text-field>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="otherReserveCheck()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
    <Warning :warning.sync="warning" @regist="regist()"></Warning>
  </v-dialog>
</template>
<script>
import axios from "axios";
import DatePicker from "@/components/room/DatePicker";
import Warning from "@/components/room/Warning";
import moment from "moment";

export default {
  components: { DatePicker, Warning },
  props: ["user", "events"],
  data() {
    return {
      warning: false,
      snackbar: false,
      msg: "",
      date: [],
      dialog: false,
      valid: false,
      roomList: [],
      userList: [],
      textfieldRule: [(v) => !!v || "入力してください"],
      selectfieldRule: [(v) => !!v || "選択してください"],
      selectedRoom: "",
      selectedUser: {},
      selectedDay: "",
      registData: {
        timefrom: "",
        timeto: "",
        purpose: "",
      },
    };
  },

  methods: {
    click() {
      this.getRoomList();
      this.getUserList();
      this.setUser();
    },
    createSedData() {
      const senddataList = [];
      this.date.forEach((elem) => {
        const dateSplit = elem.split("-");
        const yyyy = Number(dateSplit[0]);
        const mm = Number(dateSplit[1]);
        const dd = Number(dateSplit[2]);

        const data = {
          roomid: this.selectedRoom.roomid,
          roomname: this.selectedRoom.name,
          color: this.selectedRoom.color,
          timefrom: new Date(
            yyyy,
            mm - 1,
            dd,
            this.registData.timefrom.substring(0, 2),
            this.registData.timefrom.substring(3)
          ),
          timeto: new Date(
            yyyy,
            mm - 1,
            dd,
            this.registData.timeto.substring(0, 2),
            this.registData.timeto.substring(3)
          ),
          userid: this.selectedUser.id,
          username: this.selectedUser.name,
          purpose: this.registData.purpose,
          registuser: this.user.id,
          registusername: this.user.name,
        };
        senddataList.push(data);
      });
      return senddataList;
    },

    otherReserveCheck() {
      if (
        this.$refs.form.validate() &&
        this.$refs.datepicker.$refs.form.validate() &&
        this.checkTime()
      ) {
        const targetList = this.createSedData();
        const format = "YYYY-MM-DD HH:mm";
        const otherReserveList = [];
        new Promise((resolve) => {
          targetList.forEach((target) => {
            const timefrom = moment(target.timefrom, format);
            const timeto = moment(target.timeto, format);
            this.events.forEach((elem) => {
              if (
                elem.roomid == target.roomid &&
                (moment(elem.start, format).isBetween(
                  timefrom,
                  timeto,
                  undefined,
                  "[]"
                ) ||
                  moment(elem.end, format).isBetween(
                    timefrom,
                    timeto,
                    undefined,
                    "[]"
                  ) ||
                  timefrom.isBetween(
                    moment(elem.start, format),
                    moment(elem.end, format),
                    undefined,
                    "[]"
                  ) ||
                  timeto.isBetween(
                    moment(elem.start, format),
                    moment(elem.end, format),
                    undefined,
                    "[]"
                  ))
              ) {
                otherReserveList.push(elem);
              }
            });
            resolve();
          });
        }).then(() => {
          if (otherReserveList.length == 0) {
            this.regist();
          } else {
            this.warning = true;
          }
        });
      }
    },

    regist() {
      axios
        .post(process.env.VUE_APP_API_URL + "/regist-roomreserve", {
          registData: this.createSedData(),
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.clear();
            this.$emit("regist");
          }
        });
    },
    getRoomList() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-room", {
          params: {},
        })
        .then((response) => {
          this.roomList.splice(0);
          response.data.roomList.forEach((elem) => {
            this.roomList.push(elem);
          });
        });
    },
    getUserList() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-list", {})
        .then((response) => {
          this.userList.splice(0);
          response.data.forEach((elem) => {
            this.userList.push(elem);
          });
        });
    },
    setUser() {
      this.selectedUser = this.user;
    },
    clear() {
      this.date.splice(0);
      this.dialog = false;
      this.selectedRoom = "";
      this.selectedUser = "";
      this.registData = {
        timefrom: "",
        timeto: "",
        purpose: "",
      };
      this.$refs.form.resetValidation();
      this.$refs.datepicker.$refs.form.resetValidation();
    },
    cancel() {
      this.clear();
    },
    formatTimeto() {
      if (this.registData.timeto.substring(3) == "00") {
        var hh = this.registData.timeto.substring(0, 2);
        if (Number(hh) - 1 < 0) {
          hh = "23";
        } else {
          hh = (Number(hh) - 1).toString();
        }
        this.registData.timeto = hh.padStart(2, "0") + ":59";
      }
    },
    checkTime() {
      const from = moment("1990-01-01 " + this.registData.timefrom);
      const to = moment("1990-01-01 " + this.registData.timeto);
      if (from.isBefore(to)) {
        return true;
      } else {
        this.msg = "日付が不正です";
        this.snackbar = true;
        return false;
      }
    },
  },
  mounted() {
    this.setUser();
    this.getUserList();
  },
};
</script>
<style>
.fixed_btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10000;
}
</style>