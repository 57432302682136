<template>
  <v-dialog v-model="warning" persistent max-width="350">
    <v-card>
      <v-card-title class="text-h5"> </v-card-title>
      <v-card-text
        >部屋・時間が重複している予約があります。よろしいですか？</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> はい </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["warning"],
  data() {
    return {};
  },
  methods: {
    send() {
      this.$emit("regist");
      this.$emit("update:warning", false);
    },
    cancel() {
      this.$emit("update:warning", false);
    },
  },
};
</script>