<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-form v-model="valid" ref="form">
        <v-text-field
          :rules="selectfieldRule"
          v-model="date"
          label="日付(複数選択可能)"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </v-form>
    </template>

    <v-date-picker
      v-model="date"
      scrollable
      locale="ja"
      :allowed-dates="allowedDates"
      :day-format="(date) => new Date(date).getDate()"
      multiple
    >
      <v-spacer></v-spacer>
      <v-btn text @click="date.splice(0)"> クリア </v-btn>
      <v-btn text color="primary" @click="send()"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
import moment from "moment";
import horidayJp from "@holiday-jp/holiday_jp";
export default {
  props: [],
  data: () => ({
    date: [],
    valid: false,
    menu: false,
    modal: false,
    selectfieldRule: [(v) => v.length >= 1 || "選択してください"],
    allowedDates: (val) =>
      moment(val).day() !== 0 &&
      moment(val).day() !== 6 &&
      !horidayJp.isHoliday(val),
  }),
  methods:{
    send(){
      this.$refs.dialog.save(this.date)
      this.$emit('update:date',this.date)
    }
  }
};
</script>